.CreatePokeblobPage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreatePokeblobPage-container {
    margin-top: 10vh;
    max-width: 20vw;
    min-width: 300px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

.CreatePokeblobPage-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.CreatePokeblobPage-label {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
    color: var(--color-accent-b);
}

.CreatePokeblobPage-input {
    padding: 10px;
    font-size: 28px;
    border: 1px solid var(--color-accent-e);
    background-color: var(--color-accent-e);
    color: var(--color-highlight);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.2s ease;
}

.CreatePokeblobPage-input:focus {
    border-color: var(--color-highlight);
    outline: none;
}


.CreatePokeblobPage-button {
    background-color: var(--color-accent-b);
    color: var(--color-highlight);
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    width: 100%;
    box-sizing: border-box;
}

.CreatePokeblobPage-button:disabled {
    background-color: var(--color-accent-d);
    color: var(--color-accent-a);
    cursor: not-allowed;
}

.CreatePokeblobPage-button:not(:disabled):hover {
    background-color: var(--color-accent-a);
}


.CreatePokeblobPage-link {
    text-decoration: none;
}