.App {
  text-align: center;
}

.CanvasGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}